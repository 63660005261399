import React, { useEffect, useState, useRef } from 'react'
import PageWrapper from '../../components/template/PageWrapper/PageWrapper'
import PageTitle from '../../components/template/PageTitle/PageTitle'
import PageCard from '../../controls/PageCard/PageCard'
import Row from '../../controls/Row/Row'
import Col from '../../controls/Col/Col'
import API from '../../modules/api'
import AttachsList from '../../components/Attachments/AttachsList'
import $ from 'jquery'
import Utils from '../../modules/utils';

function Attachments() {
    const util = new Utils();
    const [fileState, setFileState] = useState([])
    const [attachsState, setAttachsState] = useState([])
    const [showModal, setShowModal] = useState(false);
    const [childModal, setchildModal] = useState(null);
    const [titleModal, settitleModal] = useState("");
    const [selectedLang, setSelectedLang] = useState(null);
    const [selectedAction, setSelectedAction] = useState(null);
    useEffect(() => {

        (new API()).sendGETWithToken("/api/attachments", (result) => {
            setAttachsState(result);
        })

    }, [])
    const AddFileClicked = (event) => {

        $('#file').click();


    }

    const AddFileInputClicked = (event) => {
        console.log("FFF", event.target.files);



        (new API()).sendPostFileWithToken("/api/attachments", event.target.files[0], function (result) {
            console.log(result);
            setAttachsState(JSON.parse(result));
        })
    }
    const deleteItem = (item) => {
        util.showDeleteMessage(() => {
            (new API()).sendPostWithToken(`/api/attachments/delete/${item.id}`,{}, (result) => {
             //   console.log('result', result)
                setAttachsState([...result]);
            })
        })

    }

    const AttachItemClicked = (event, item, action) => {
        event.preventDefault();
        switch (action) {
            case "delete":
deleteItem(item);
                break;

            default:
                break;
        }

    }
    return (
        <PageWrapper>
            <PageTitle title="فایلهای سیستم" />
            <Row>
                <Col size="lg-12">

                    <PageCard>
                        <div className="btn btn-list mb-2 ">

                            <button className="btn ripple btn-primary" onClick={AddFileClicked}>
                                <i className="fe fe-plus-circle mx-1 mt-3"></i>
                                فایل جدید </button>
                            <input type='file' name='file' id='file' className='d-none' onChange={(event) => AddFileInputClicked(event)} />
                        </div>

                        <div className='row row-sm py-3' style={{ background: "#f0f0ff" }}>
                            {

                                attachsState.map((item) => {
                                    return <AttachsList item={item} click={AttachItemClicked} key={item.id} />

                                })

                            }
                        </div>

                    </PageCard>
                </Col>
            </Row>
        </PageWrapper>
    )
}

export default Attachments