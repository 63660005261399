import React, { useEffect, useState } from 'react'
import TextBox from '../../controls/TextBox/TextBox';
import TextArr from '../../controls/TextArr/TextArr';
import { useAsyncError } from 'react-router';
import AttachSelector from '../../components/Attachments/AttachSelector/AttachSelector'
import * as appConsts from '../../store/consts'

function AddVideo({ item, index,change }) {
    const [itemData,setItemData]=useState(item);

    const [filterModal, setFilterModal] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedImageState, setSelectedImage] = useState(null)

    useEffect(()=>{
        console.log('item', item)
        setItemData({...item})
    },[item])
    const handleChange = (e) => {
        const { name, value } = e.target;


        // alert(name)
        // alert(value)
        setItemData({
            ...itemData,
            [name]: value,
        });

    };
    const handleClickChange=()=>{
        console.log('itemData', itemData)
        change(itemData,index)
    }


    const imageDoubleClick = (event,index) => {
        event.preventDefault();
       // setSelectedAddVideoIndexState(index);
        setFilterModal(["image"])
        setShowModal(true)
        setSelectedImage(event.target);
       
    }
    const videoDoubleClick = (event,index) => {
        event.preventDefault();
      //  setSelectedAddVideoIndexState(index);
        setFilterModal(["video"])
        setShowModal(true)
        setSelectedImage(event.target);
       
    }
    const closeModal = (event) => {
        event.preventDefault();
        setShowModal(false)
    }
    const selectedImage = (event, item) => {
        event.preventDefault();
        if (selectedImageState != null) {

            // (new API()).sendPostWithToken("/api/settings", { name: `home-product-${selectedImageState.id}-attach-id`, val: item.id }, (result) => {
            //     setHomeState({
            //         ...homeState,
            //         [`${selectedImageState.id}_url`]: `${appConsts.api_url}/api/attachments/file/${item.id}`
            //     })
            // })
            // })
            
            // if(selectedAddVideoIndexState!=null){
            //     let temp=[...addVideoStates]
            //     alert(filterModal)
                
            // console.log(temp[selectedAddVideoIndexState]);
            // console.log(temp);
            //         setAddVideoStates([...temp])
            // }
           
            if(filterModal.length>0 && filterModal[0]=="image")
                setItemData({
                    ...itemData,
                    src: `${item.id}`,
                });
            else  if(filterModal.length>0 && filterModal[0]=="video")
                setItemData({
                    ...itemData,
                    video: `${item.id}`,
                });

            setShowModal(false)
        }

    }
    return (
        <div class="col-md-6 col-lg-3 ">
            <div class="card overflow-hidden custom-card border">
                <div class="card-body ">
                    <TextBox title="عنوان" name="title" value={itemData.title} changed={handleChange} />
                    <TextBox title="لینک" name="link" value={itemData.link} changed={handleChange} />

                    <TextArr title="توضیح" name="dsc" value={itemData.dsc} changed={handleChange} />
                </div>
                <video className='border mx-2' onDoubleClick={(event) => videoDoubleClick(event, index)} class="img-fluid b-img" src={`${appConsts.api_url}/api/attachments/file/${itemData.video}`}></video>

                <img alt="تصویر" onDoubleClick={(event)=>imageDoubleClick(event,index)} class="img-fluid b-img mt-2" src={itemData.src!=null?`${appConsts.api_url}/api/attachments/file/${itemData.src}`:'assets/img/media/4.jpg'}/>
                <button className="btn ripple btn-primary w-100" onClick={handleClickChange} >
                            <i className="fe fe-plus-circle mx-1 mt-3"></i>
                            ذخیره تغییرات </button>
                   
            </div>
            {showModal ? <AttachSelector isShow={showModal} close={closeModal} filter={filterModal} select={selectedImage} /> : null}

        </div>
    )
}

export default AddVideo