import React, {  useEffect, useState } from 'react'
import API from '../../modules/api'
import PageWrapper from '../../components/template/PageWrapper/PageWrapper'
import PageTitle from '../../components/template/PageTitle/PageTitle'
import PageCard from '../../controls/PageCard/PageCard'
import Row from '../../controls/Row/Row'
import Col from '../../controls/Col/Col'
import Table from '../../controls/Table/Table'
import THead from '../../controls/Table/THead'
import UsersList from '../../components/Users/UsersList'
import Modal from '../../controls/Modal/Modal'
import EditUser from '../../components/Users/EditUser'
import AddUser from '../../components/Users/AddUser'
import UserProducts from '../../components/Users/UserProducts'
import Swal from 'sweetalert2';
import Utils from '../../modules/utils';


function Users() {

  const util = new Utils();
  
  const [usersState, setUsersState] = useState([])
  const [showModal, setShowModal] = useState(false);
  const [childModal, setchildModal] = useState(null);
  const [titleModal, settitleModal] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedAction, setSelectedAction] = useState(null);
  useEffect(() => {

    (new API()).sendGETWithToken("/api/users", (result) => {
      setUsersState(result);
    })

  }, [])
  // useEffect(useCallback(() => {

  //   (new API()).sendGETWithToken("/api/users", (result) => {
  //     setUsersState(result);
  //   })

  // },[usersState]), [usersState,setSelectedAction])

  const addUserClicked = (event) => {
    event.preventDefault();
    setSelectedUser(null)
    settitleModal("افزودن کاربر")
    setSelectedUser({})
    setSelectedAction("add")
    setchildModal(<AddUser item={null} changed={onSetUserUpdate} />)
    setShowModal(true)
  }
  const onSaveModal = (event) => {
    event.preventDefault();

    switch (selectedAction) {
      case "edit":
        if (selectedUser) {
          (new API()).sendPatchWithToken(`/api/users/${selectedUser.id}`, selectedUser, (result) => {
            console.log(result);
            setSelectedAction(null)
            setSelectedUser(null)
            setShowModal(false)
              //console.clear()
              setUsersState(result);
            
          })
        }
        break;
        case "add":
          if (selectedUser) {
            (new API()).sendPostWithToken(`/api/users`, selectedUser, (result) => {
              console.log(result);
              setSelectedAction(null)
              setSelectedUser(null)
              setShowModal(false)
                //console.clear()
                setUsersState(result);
              
            },(err)=>{
if(err.response.data.code==520){
  Swal.fire({
    title: 'خطا!',
    text: err.response.data.message,
    icon: 'error',
    confirmButtonText: 'باشه'
  })
}else{
  Swal.fire({
    title: 'خطا!',
    text: "خطا در ارسال اطلاعات",
    icon: 'error',
    confirmButtonText: 'باشه'
  })
}
             
            })
          }
          break;
  
      default:
        break;
    }




  }

  const closeUserClicked = (event) => {
    event.preventDefault();
    setSelectedUser(null)
    setShowModal(false)
  }
  const onSetUserUpdate = (item) => {
    //event.preventDefault();
    console.log("VVV", item)
    setSelectedUser(item)
  }
  const userItemClicked = (event, item, action) => {
    event.preventDefault();
    setSelectedUser(null)
    switch (action) {
      case "delete":
        util.showDeleteMessage(() => {
          (new API()).sendPostWithToken(`/api/users/delete/${item.id}`, {}, (result) => {
            setSelectedAction(null)
            setSelectedUser(null)
            setShowModal(false)
              //console.clear()
              setUsersState([...result]);
            util.showAlert("تائید!", "اطلاعات تغییر یافت");
          })
        });
        break;
      case "edit":
        settitleModal("ویرایش کاربر")
       
        setSelectedUser(item)
        setSelectedAction(action)
        setchildModal(<EditUser item={item} changed={onSetUserUpdate} />)
        setShowModal(true)
        break;
        case "products":
          settitleModal("محصولات کاربر")
         
          setSelectedUser(item)
          setSelectedAction(action)
          setchildModal(<UserProducts item={item} changed={onSetUserUpdate} />)
          setShowModal(true)
          break;
      default:
        break;
    }
  }

  return (
    <PageWrapper>
      <PageTitle title="کاربران" />
      <Row>
        <Col size="lg-12">

          <PageCard>
            <div className="btn btn-list mb-2">
              <button className="btn ripple btn-primary" onClick={addUserClicked}>
                <i className="fe fe-plus-circle mx-1 mt-3"></i>
                کاربر جدید </button>
            </div>
            <Table>
              <THead>
                <th>#</th>
                <th>نام</th>
                <th>نام خانوادگی</th>
                <th>نام کاربری</th>
                <th>موبایل</th>
                <th>کد ترید</th>
              </THead>
              <tbody>
                {

                  usersState.map((item) => {
                    return <UsersList item={item} click={userItemClicked} />

                  })

                }
              </tbody>
            </Table>
            <Modal isShow={showModal} close={closeUserClicked} title={titleModal} saveClick={(event) => onSaveModal(event)}>
              {childModal}
            </Modal>
          </PageCard>
        </Col>
      </Row>

    </PageWrapper>

  )
}

export default Users