import React, { useEffect, useState, useRef, useCallback } from 'react'
import PageTitle from '../../../components/template/PageTitle/PageTitle'
import PageWrapper from '../../../components/template/PageWrapper/PageWrapper'
import PageCard from '../../../controls/PageCard/PageCard'
import Row from '../../../controls/Row/Row'
import Col from '../../../controls/Col/Col'
import API from '../../../modules/api'
import TextBox from '../../../controls/TextBox/TextBox';

function ContactUs() {
    const [controlsStates, setControlsStates] = useState([
        { title: "تلفن", name: "tel", type: "sample" },
        { title: "آدرس", name: "address", type: "sample" },
        { title: "ایمیل", name: "email", type: "sample" },
        { title: "فرم", name: "form", type: "form" },
        { title: "نقشه", name: "map", type: "map" },

    ])
    const [langStates, setLangStates] = useState([])

    const [textStates, setTextStates] = useState({



    })
    const loadLangs = () => {
        let obj = {};
        (new API()).sendGETWithToken("/api/langs", (result) => {
            setLangStates(result);
            // result.map((item)=>{
            //     setTextStates({...textStates,obj})
            // })

        })
    }

    useEffect(() => {
        loadLangs();

    }, [])
    useEffect(() => {
        loadData();

    }, [langStates])



    const loadData = () => {

        let arrNames = [];

        langStates.map((item) => {

            controlsStates.map((control) => {

                if (control.type == "sample") {
                    const names = ['title', 'dsc', 'data']
                    names.map((item2) => {
                        const name = `contactus_${control.name}_${item2}_${item.name}`
                        if (arrNames.indexOf(name) == -1) {
                            arrNames = [...arrNames, name];
                        }
                    })
                }
                if (control.type == "form") {
                    const names = ['title', 'dsc', 'name', 'email', 'country', 'message', 'button']
                    names.map((item2) => {
                        const name = `contactus_${control.name}_${item2}_${item.name}`
                        if (arrNames.indexOf(name) == -1) {
                            arrNames = [...arrNames, name];
                        }
                    })
                }
                if (control.type == "map") {
                    const names = ['link']
                    names.map((item2) => {
                        const name = `contactus_${control.name}_${item2}_${item.name}`
                        if (arrNames.indexOf(name) == -1) {
                            arrNames = [...arrNames, name];
                        }
                    })
                }

            })

        })
        console.log('ABC', arrNames)
        let obj = { ...textStates };
        try {

            (new API()).sendPostWithoutToken(`/api/settings/names`, { names: arrNames }, (result) => {
                console.log("TRE", result)
                arrNames.forEach((item) => {
                    if (result[item] != null) {
                        obj[item] = result[item];
                    }
                })



                console.log("WERTY", obj)
                setTextStates({ ...textStates, ...obj });
            });

        } catch (error) {

            console.log("ERR", error)
        }



    }

    const handleChange = (e) => {
        const { name, value } = e.target;


        // alert(name)
        // alert(value)
        setTextStates({
            ...textStates,
            [name]: value,
        });

    };
    const handleSaveClicked = () => {
        let arrNames = Object.keys(textStates).map((item) => {

            (new API()).sendPostWithToken("/api/settings", { name: item, val: textStates[item] }, (result) => {


            })

        });

    }
    return (
        <PageWrapper>
            <PageTitle title="تنظیمات تماس با ما" />
            <div className="btn btn-list mb-2">
                <button className="btn ripple btn-primary px-3" onClick={handleSaveClicked}>
                    <i className="fe fe-plus-circle mx-1 mt-3"></i>
                    ذخیره </button>
            </div>
            <Row>
                <Col size="lg-12">

                    {
                        controlsStates.map((control) => {

                            return <>
                                {
                                    langStates.map((item) => {

                                        return <PageCard>
                                            <h4>{control.title} -{item.title}</h4>
                                            {control.type == "sample" && <>
                                                <div className='row'>

                                                    <div className="col-12 col-md-6 col-lg-4 ">

                                                        <TextBox title="عنوان" name={`contactus_${control.name}_title_${item.name}`} value={textStates[`contactus_${control.name}_title_${item.name}`]} changed={handleChange} />
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-4 ">
                                                        <TextBox title="توضیحات" name={`contactus_${control.name}_dsc_${item.name}`} value={textStates[`contactus_${control.name}_dsc_${item.name}`]} changed={handleChange} />
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-4 ">
                                                        <TextBox title="داده" name={`contactus_${control.name}_data_${item.name}`} value={textStates[`contactus_${control.name}_data_${item.name}`]} changed={handleChange} />
                                                    </div>


                                                </div>


                                            </>}
                                            {control.type == "form" && <>
                                                <div className='row'>

                                                    <div className="col-12 col-md-6 col-lg-4 ">

                                                        <TextBox title="عنوان" name={`contactus_${control.name}_title_${item.name}`} value={textStates[`contactus_${control.name}_title_${item.name}`]} changed={handleChange} />
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-4 ">
                                                        <TextBox title="توضیحات" name={`contactus_${control.name}_dsc_${item.name}`} value={textStates[`contactus_${control.name}_dsc_${item.name}`]} changed={handleChange} />
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-4 ">
                                                        <TextBox title="نام" name={`contactus_${control.name}_name_${item.name}`} value={textStates[`contactus_${control.name}_name_${item.name}`]} changed={handleChange} />
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-4 ">
                                                        <TextBox title="ایمیل" name={`contactus_${control.name}_email_${item.name}`} value={textStates[`contactus_${control.name}_email_${item.name}`]} changed={handleChange} />
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-4 ">
                                                        <TextBox title="کشور" name={`contactus_${control.name}_country_${item.name}`} value={textStates[`contactus_${control.name}_country_${item.name}`]} changed={handleChange} />
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-4 ">
                                                        <TextBox title="پیام" name={`contactus_${control.name}_message_${item.name}`} value={textStates[`contactus_${control.name}_message_${item.name}`]} changed={handleChange} />
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-4 ">
                                                        <TextBox title="متن دکمه" name={`contactus_${control.name}_button_${item.name}`} value={textStates[`contactus_${control.name}_button_${item.name}`]} changed={handleChange} />
                                                    </div>


                                                </div>


                                            </>}
                                            {control.type == "map" && <>

                                                <div className='row'>

                                                    <div className="col-12">

                                                        <TextBox title="لینک" name={`contactus_${control.name}_link_${item.name}`} value={textStates[`contactus_${control.name}_link_${item.name}`]} changed={handleChange} />
                                                    </div>


                                                </div>

                                            </>}

                                        </PageCard>
                                    })
                                }
                            </>

                        })

                    }





                </Col>
            </Row>
        </PageWrapper>
    )
}

export default ContactUs