import React, { useEffect, useState } from 'react'
import TextBox from '../../controls/TextBox/TextBox';
import TextArr from '../../controls/TextArr/TextArr';

function AddFQ(props) {
    const [itemUseState, setItemUseState] = useState({})

    useEffect(() => {
        if (props.item) {
            setItemUseState({...props.item});
        }
    }, [props.item]);

    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;


       // alert(name)
       // alert(value)
        setItemUseState({
            ...itemUseState,
            [name]: value,
        });

    };

    useEffect(() => {

        console.log(itemUseState)
        props.changed(itemUseState)
    }, [itemUseState])
    return (
        <div className="row row-sm">
            <div className="col-md-12 col-lg-12 col-xl-12">
                <div className="">
                    
                    <TextArr title="پرسش" name="question" value={itemUseState.question} changed={handleChange} />
                    <TextArr title="پاسخ" name="answer" value={itemUseState.answer} changed={handleChange} />
                  



                  

                    


                </div>
            </div>
        </div>
    )
}

export default AddFQ