import React, { useCallback, useEffect, useState } from 'react'
import API from '../../../modules/api'
import PageWrapper from '../../../components/template/PageWrapper/PageWrapper'
import PageTitle from '../../../components/template/PageTitle/PageTitle'
import PageCard from '../../../controls/PageCard/PageCard'
import Row from '../../../controls/Row/Row'
import Col from '../../../controls/Col/Col'
import Utils from '../../../modules/utils';
import * as appConsts from '../../../store/consts'
import AttachSelector from '../../../components/Attachments/AttachSelector/AttachSelector'
import AddVideo from '../../../components/Videos/AddVideo'
import TextBox from '../../../controls/TextBox/TextBox';
import TextArr from '../../../controls/TextArr/TextArr';
function Videos() {
 const [showElement, setShowElement] = useState(true);

    const [filterModal, setFilterModal] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [isChangeState, setIsChangeState] = useState(false);
    const [selectedImageState, setSelectedImage] = useState(null)
    const [addVideoStates, setAddVideoStates] = useState([])
    const [selectedAddVideoIndexState, setSelectedAddVideoIndexState] = useState(null)
    const [homeState, setHomeState] = useState({
        title: "Read latest story",
        videos: []

    })
    useEffect(() => {
        console.log('ERTR', homeState);
        console.log('ERTR', isChangeState);
        if (isChangeState) {

            (new API()).sendPostWithToken("/api/settings", { name: `page-videos`, val: JSON.stringify(homeState) }, (result) => {
                setIsChangeState(false);

            })
        }

    }, [homeState, isChangeState])

    const onShowElementClicked = (val) => {

        setShowElement(val);

    }
    useEffect(() => {

        (new API()).sendPostWithToken("/api/settings", { name: `page-videos-show`, val: `${showElement}` }, (result) => {


        })
    }, [showElement])

    const imageDoubleClick = (event, index) => {
        event.preventDefault();
        setSelectedAddVideoIndexState(index);
        setFilterModal(["image"])
        setShowModal(true)
        setSelectedImage(event.target);

    }
    const videoDoubleClick = (event, index) => {
        event.preventDefault();
        setSelectedAddVideoIndexState(index);
        setFilterModal(["video"])
        setShowModal(true)
        setSelectedImage(event.target);

    }
    const closeModal = (event) => {
        event.preventDefault();
        setShowModal(false)
    }
    const selectedImage = (event, item) => {
        event.preventDefault();
        if (selectedImageState != null) {

            // (new API()).sendPostWithToken("/api/settings", { name: `home-product-${selectedImageState.id}-attach-id`, val: item.id }, (result) => {
            //     setHomeState({
            //         ...homeState,
            //         [`${selectedImageState.id}_url`]: `${appConsts.api_url}/api/attachments/file/${item.id}`
            //     })
            // })
            // })
            alert(selectedAddVideoIndexState)
            if (selectedAddVideoIndexState != null) {
                let temp = [...addVideoStates]
                alert(filterModal)
                if (filterModal.length > 0 && filterModal[0] == "image")
                    temp[selectedAddVideoIndexState].src = `${appConsts.api_url}/api/attachments/file/${item.id}`
                else if (filterModal.length > 0 && filterModal[0] == "video")
                    temp[selectedAddVideoIndexState].video = `${appConsts.api_url}/api/attachments/file/${item.id}`

                console.log(temp[selectedAddVideoIndexState]);
                console.log(temp);
                setAddVideoStates([...temp])
            }


            setShowModal(false)
        }

    }



    const handlerOnAddVideoClicked = () => {
        setAddVideoStates([...addVideoStates, { src: null, video: null, title: "عنوان", dsc: "محل درج توضیحات", link: "" }])
    }
    const handlerOnAddChangeClicked = (item, index) => {
        let temp = [...homeState.videos]
        temp.push(item);
        console.log('item', item)
        setHomeState({ ...homeState, videos: [...temp] })

        temp = [...addVideoStates]
        temp.splice(index, 1);
        setAddVideoStates([...temp]);
        setIsChangeState(true);
    }
    const handlerOnEditChangeClicked = (item, index) => {

        let temp = [...homeState.videos];
        temp[index] = item;

        setHomeState({ ...homeState, videos: [...temp] });
        setIsChangeState(true);
    }




    useEffect(() => {
        loadData();



    }, [])


    const loadData = () => {

        let arrNames = ['page-videos', 'page-videos-show'];

        let obj = { ...homeState };
        try {

            (new API()).sendPostWithoutToken(`/api/settings/names`, { names: arrNames }, (result) => {
                console.log("TRE", result)
                if (result['page-videos-show'] != null) {
                    setShowElement((result['page-videos-show'] == 'true'))
                }

                if (result['page-videos'] != null) {
                    const myObj = JSON.parse(result['page-videos']);
                    console.log("WERTY", myObj.videos)
                    obj = { ...myObj }


                }
                console.log("WERTY", obj)
                setHomeState({ ...homeState, ...obj });
            });

        } catch (error) {

            console.log("ERR", error)
        }



    }


    const handleTextChange = (e) => {
        const { name, value } = e.target;


        // alert(name)
        // alert(value)
        setHomeState({
            ...homeState,
            [name]: value,
        });
        setIsChangeState(true);

    };

  return (
    <PageWrapper>
    <PageTitle title="ویدئوها" />
    <Row>

            <Col size="lg-12">

                <PageCard>
                    <Row>
                        <Col size="lg-4">
                            <TextBox title="عنوان" name="title" value={homeState.title} changed={handleTextChange} />

                        </Col>
                        <Col size="lg-8">

                        </Col>
                    </Row>
                    <div className="btn btn-list mb-2">
                        <button className="btn ripple btn-primary" onClick={handlerOnAddVideoClicked}>
                            <i className="fe fe-plus-circle mx-1 mt-3"></i>
                            ویدئو جدید </button>
                    </div>
                    <Row>
                        {

                            addVideoStates.map((item, index) => {
                                return <AddVideo dbclick={imageDoubleClick} item={item} index={index} vdbclick={videoDoubleClick} change={handlerOnAddChangeClicked} />
                            })
                        }
                    </Row>
                    <Row>
                        {

                            homeState.videos.map((item, index) => {
                                return <AddVideo dbclick={imageDoubleClick} item={item} index={index} vdbclick={videoDoubleClick} change={handlerOnEditChangeClicked} />
                            })
                        }
                    </Row>
                </PageCard>
            </Col>
            {showModal ? <AttachSelector isShow={showModal} close={closeModal} filter={filterModal} select={selectedImage} /> : null}

        </Row>
</PageWrapper>
  )
}

export default Videos