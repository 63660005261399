import React, { useCallback, useEffect, useState } from 'react'
import API from '../../../../modules/api'
import PageWrapper from '../../../../components/template/PageWrapper/PageWrapper'
import PageTitle from '../../../../components/template/PageTitle/PageTitle'
import PageCard from '../../../../controls/PageCard/PageCard'
import Row from '../../../../controls/Row/Row'
import Col from '../../../../controls/Col/Col'
import * as appConsts from '../../../../store/consts'

import AttachSelector from '../../../../components/Attachments/AttachSelector/AttachSelector'

function SiteLogos() {
    const [titleStates, setTitleStates] = useState(["فوتر سایت","هدر سایت","لاگین سایت","لاگین نقشها","لوگوی نقشها"]);
    const [filterModal, setFilterModal] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedImageState, setSelectedImage] = useState(null);
    const [homeState, setHomeState] = useState(
        {
            home_footer_logo: "./assets/front-images/Logo.png",
            home_header_logo: "./assets/front-images/Logo.png",
            site_login_logo: "./assets/front-images/Logo.png",
            roles_login_logo: "./assets/front-images/Logo.png",
            roles_logo: "./assets/front-images/Logo.png",
        })


        const closeModal = (event) => {
            event.preventDefault();
            setShowModal(false)
        }

        const selectedImage = (event, item) => {
            event.preventDefault();
            if (selectedImageState != null) {

                                (new API()).sendPostWithToken("/api/settings", { name: `${selectedImageState.id.replaceAll('_','-')}-attach-id`, val: item.id }, (result) => {
                    setHomeState({
                        ...homeState,
                        [`${selectedImageState.id}`]: `${appConsts.api_url}/api/attachments/file/${item.id}`
                    })
                })
    
                setShowModal(false)
            }
    
        }


        const imageDoubleClick = (event) => {
            event.preventDefault();
            console.log('event.target', event.target)
            setFilterModal(["image"])
            setShowModal(true)
           
            setSelectedImage(event.target);
        }  


        useEffect(() => {
            loadData();
    
    
    
        }, [])
        const loadData = () => {

            let arrNames = [];
            Object.keys(homeState).forEach((el)=> arrNames=[...arrNames,`${el.replaceAll('_','-')}-attach-id`])
            let obj = { ...homeState };
         
           console.log('arrNames', arrNames);
            (new API()).sendPostWithoutToken(`/api/settings/names`, { names: arrNames }, (result) => {
                console.log("TRE", result)

                arrNames.forEach((el)=>{

                    if (result[el] != null){
                        obj[el.replace('-attach-id','').replaceAll('-','_')] = `${appConsts.api_url}/api/attachments/file/${result[el]}`;
                    }
                    

                })

             
                  setHomeState({ ...homeState, ...obj });
            });
    
    
        }



    return (
        <PageWrapper>
            <PageTitle title="لوگوهای سایت" />
            <Row>
                <Col size="lg-12">

                    <PageCard>
                     <Row>
                     {
                            Object.keys(homeState).map((item,index) => {
                               return <Col size="lg-4 mt-2">
                                <figure className="pos-relative mg-b-0">
                                    <img alt="Responsive image" className="img-fit-cover" id={`${item}`} style={{background:"#f00"}} onDoubleClick={(event) => imageDoubleClick(event)} src={homeState[item]} />
                                  
                                </figure>
                                <h6 className="tx-14 tx-sm-16  tx-semibold mg-b-15 mg-sm-b-20 mt-2">{titleStates[index]}</h6>
                               </Col>


                            })
                        }
                     </Row>
                    </PageCard>
                </Col>
            </Row>
            {showModal ? <AttachSelector isShow={showModal} close={closeModal} filter={filterModal} select={selectedImage} /> : null}

        </PageWrapper>
    )
}

export default SiteLogos