import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router';
import API from '../../modules/api'
import PageWrapper from '../../components/template/PageWrapper/PageWrapper'
import PageTitle from '../../components/template/PageTitle/PageTitle'
import PageCard from '../../controls/PageCard/PageCard'
import Row from '../../controls/Row/Row'
import Col from '../../controls/Col/Col'
import Table from '../../controls/Table/Table'
import THead from '../../controls/Table/THead'
import Modal from '../../controls/Modal/Modal'
import WordsList from '../../components/Words/WordsList'
import EditWord from '../../components/Words/EditWord'
import AddWord from '../../components/Words/AddWord'
import { useNavigate } from "react-router-dom";
import Utils from '../../modules/utils';

function Words(props) {
    const util = new Utils();
    const { id } = useParams();
    const [langState, setLangState] = useState([])
    const [wordsState, setWordsState] = useState([])
    const [showModal, setShowModal] = useState(false);
    const [childModal, setchildModal] = useState(null);
    const [titleModal, settitleModal] = useState("");
    const [selectedWordState, setSelectedWord] = useState(null);
    const [selectedAction, setSelectedAction] = useState(null);
    const navigate = useNavigate();
    
    useEffect(() => {
  
      (new API()).sendGETWithToken(`/api/words/lang/${id}`, (result) => {
        setLangState(result.lang)
        setWordsState(result.words);
      })
  
    }, [id])
   
  
    const GoBackClicked = (event) => {   
         navigate(`/langs`)
    }
    const AddWordClicked = (event) => {
      event.preventDefault();
      settitleModal("افزودن کلمه")
      setSelectedWord({lang_id:null,word:"pppp",translate:""})
      setSelectedAction("add")
      setchildModal(<AddWord item={{lang_id:null,word:"",translate:""}} changed={onSetWordUpdate} langState={langState} />)
      setShowModal(true)
    }
    const onSaveModal = (event) => {
      event.preventDefault();

      switch (selectedAction) {
        case "edit":
          if (selectedWordState) {
            (new API()).sendPatchWithToken(`/api/words/${selectedWordState.id}`, selectedWordState, (result) => {
              console.log(result);
              setSelectedAction(null)
              setSelectedWord(null)
              setShowModal(false)
                //console.clear()
                setWordsState(result.words);
              
            })
          }
          break;
          case "add":
            if (selectedWordState) {
                selectedWordState.lang_id=id;
              (new API()).sendPostWithToken(`/api/words`, selectedWordState, (result) => {
                console.log(result);
                setSelectedAction(null)
                setSelectedWord({})
                setShowModal(false)
                  //console.clear()
                  setWordsState(result.words);
                
              })
            }
            break;
    
        default:
          break;
      }
  
  
  
  
    }

   
    const closeWordClicked = (event) => {
      event.preventDefault();
      setSelectedWord(null)
      setShowModal(false)
    }
    const onSetWordUpdate = (item) => {
      //event.preventDefault();
      console.log("VVV", item)
      setSelectedWord(item)
      console.log("NNNN",selectedWordState)
    }
    const wordItemClicked = (event, item, action) => {
      event.preventDefault();
      setSelectedWord(null)
      switch (action) {
        case "delete":
          util.showDeleteMessage(() => {
            (new API()).sendPostWithToken(`/api/words/delete/${item.id}`, {lang_id:id}, (result) => {
              setSelectedAction(null)
              setSelectedWord(null)
              setShowModal(false)
             
              setWordsState([...result.words]);
              util.showAlert("تائید!", "اطلاعات تغییر یافت");
            })
          });
          break;
        case "edit":
          settitleModal("ویرایش کلمه")
         
          //setSelectedWord({...item})
          setSelectedWord(item)
          setSelectedAction(action)
          setchildModal(<EditWord item={item} changed={onSetWordUpdate} langState={langState} />)
          setShowModal(true)
          break;
    
       
        default:
          break;
      }
    }
  
    return (
      <PageWrapper>
        <PageTitle title={`کلمات زبان ${langState.title}`} />
        <Row>
          <Col size="lg-12">
  
            <PageCard>
              <div className="btn btn-list mb-2">
                <button className="btn ripple btn-secondary" onClick={GoBackClicked}>
                  <i className="fe fe-arrow-right mx-1 mt-3"></i>
                  بازگشت </button>
                <button className="btn ripple btn-primary" onClick={AddWordClicked}>
                  <i className="fe fe-plus-circle mx-1 mt-3"></i>
                  کلمه جدید </button>
              </div>
              <Table>
                <THead>
                  <th className='col-md-2'>#</th>
                  <th>کلمه</th>
                  <th>ترجمه</th>
               
                </THead>
                <tbody>
                  {
  
  wordsState.map((item) => {
                      return <WordsList item={item} click={wordItemClicked} />
  
                    })
  
                  }
                </tbody>
              </Table>
              <Modal isShow={showModal} close={closeWordClicked} title={titleModal} saveClick={(event) => onSaveModal(event)}>
                {childModal}
              </Modal>
            </PageCard>
          </Col>
        </Row>
  
      </PageWrapper>
  
  )
}

export default Words