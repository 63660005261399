export const api_url="https://api.tradinglocally.com";
// export const api_url="http://127.0.0.1:3000";
export const booleanData=[
    {label:"خیر",value:0},
    {label:"بلی",value:1},
]
export const subSysData=[
    {label:"مدیر",value:1},
    {label:"پشتیبان",value:2},
    {label:"کاربر",value:3},
]
export const PakageTypes=[
    {label:"طلائی",value:1},
    {label:"نقره ای",value:2},
    {label:"برنزی",value:3},
    {label:"عادی",value:4},
]


export const Directions=[
    {label:"راست به چپ",value:"rtl"},
    {label:"چپ به راست",value:"ltr"},
    
]



export const productTypeData=[
    {label:"ربات",value:1},
    {label:"اندیکاتور",value:2},
]



export const TicketStatusTypes=[
    {label:"ارسال شده",value:0},
    {label:"پاسخ داده شده",value:1},
    {label:"در حال بررسی",value:2},
    {label:"بسته شده",value:3},
]
