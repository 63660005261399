import React from 'react'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
function MyQuilEditor({ value = null,name="", change }) {
    const modules = {
        toolbar: [
            [{ header: [1, 2,3,4,5,6, false] }], // Header levels
            ['bold', 'italic', 'underline'], // Formatting buttons
            [{ list: 'ordered' }, { list: 'bullet' }], // Lists
            ['link', 'image'], // Insert links/images
            ['clean'], 
            [{ 'color': [] }],// Clear formatting
            [{ 'font': [] }],
            [{ 'size': [] }],
            [{ 'align': [] }],
        ],
    };

    const formats = [
        'header', 'bold', 'italic', 'underline',
        'list', 'bullet', 'link', 'image',"color",'size','font','align'
    ];

    return (
        <ReactQuill theme="snow"
            value={value}
            onChange={
                (content) => {
                    if(change!=null)
                    change(name,content)
                    console.log('content', content)
                }


            }
            modules={modules}
            formats={formats}
        />
    )
}

export default MyQuilEditor