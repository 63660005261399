import React, { useCallback, useEffect, useState } from 'react'
import API from '../../modules/api'
import PageWrapper from '../../components/template/PageWrapper/PageWrapper'
import PageTitle from '../../components/template/PageTitle/PageTitle'
import PageCard from '../../controls/PageCard/PageCard'
import Row from '../../controls/Row/Row'
import Col from '../../controls/Col/Col'
import Utils from '../../modules/utils';
import Modal from '../../controls/Modal/Modal'
import AddFQ from '../../components/FQs/AddFQ'
import { event } from 'jquery'
import { json } from 'react-router'

function FrequncyQuestions() {

    const util = new Utils();
    const [questionsState, setQuestionsState] = useState([])
    const [showModal, setShowModal] = useState(false);
    const [childModal, setchildModal] = useState(null);
    const [titleModal, settitleModal] = useState("");
    const [selectedQ, setSelectedQ] = useState(null);
    const [selectedAction, setSelectedAction] = useState(null);

    useEffect(()=>{
            (new API()).sendGETWithToken(`/api/settings/name/fq`, (result) => {
             
               try {
                if(result!=null)
                    setQuestionsState([...JSON.parse(result.val)])
               } catch (error) {
                
               }
    
              })
        
      
        console.log('questionsState', questionsState)
    },[])

    useEffect(()=>{
        if(selectedAction!=null && (selectedAction=="add"  || selectedAction=="delete-question")){
        (new API()).sendPostWithToken(`/api/settings`, {name:"fq",val:JSON.stringify(questionsState)}, (result) => {
           
            setSelectedAction(null)
          })
        }
        console.log('questionsState', questionsState)
    },[questionsState])

    const handlerOnSaveModal = (event) => {
        event.preventDefault();
        switch (selectedAction) {
          
            case "add":
                if (selectedQ != null && selectedQ.question != null && selectedQ.question.trim().length > 0) {
                 
                    let temp = [...questionsState];
                    temp.push({ question: selectedQ.question, answer: selectedQ.answer})
                    //setQuestionsState([...questionsState, ...{ question: selectedQ.question, answers: [] }])
                    setQuestionsState([...temp])
                   // setSelectedQ(...{ question: "" })
                    setShowModal(false)
                 
                }
                break;
           

            default:
                break;
        }




    }
    const handlerAddClicked = (event) => {
        event.preventDefault();
        settitleModal("افزودن پرسش")
        setSelectedQ({})
        setSelectedAction("add")
        setchildModal(<AddFQ item={{ question: "" ,answer:""}} changed={handlerOnSetUpdate} />)
        setShowModal(true)
    }
    


    const handlerOnSetUpdate = (item) => {
        //event.preventDefault();
        console.log("VVV", item)
        setSelectedQ(item)
    }
   


    const handlerCloseClicked = (event) => {
        event.preventDefault();
        setSelectedQ(null)
        setShowModal(false)
    }
    const handlerDeleteQuestionClicked = (event, item) => {
        event.preventDefault();
        util.showDeleteMessage(() => {
        let temp=[...questionsState];
        temp.splice(item, 1)
        setQuestionsState([...temp])
        setSelectedAction("delete-question")
    });
    }
   
    return (
        <PageWrapper>
            <PageTitle title="سوالات متداول" />
            <Row>
                <Col size="lg-12">

                    <PageCard>
                        <div className="btn btn-list mb-2">
                            <button className="btn ripple btn-primary" onClick={handlerAddClicked}>
                                <i className="fe fe-plus-circle mx-1 mt-3"></i>
                                سوال جدید </button>
                        </div>
                    </PageCard>
                </Col>
                <Col size="lg-12">


                    <div aria-multiselectable="true" class="accordion accordion-color" id="accordion2" role="tablist">
                        {questionsState.map((item, index) => {

                            return <div class="card">
                                <div class="card-header" id={`headingOne${index}`} role="tab">

                                
                                <a aria-controls="collapseOne2" aria-expanded="false" data-toggle="collapse" href={`#collapseOne${index}`} class="collapsed d-flex"><button class="btn ripple btn-secondary mx-2 btn-icon" onClick={(event) => handlerDeleteQuestionClicked(event, index)}><i class="fe fe-trash"></i></button><span className='mt-2'>{item.question}</span>   </a>
                                </div>
                                <div aria-labelledby={`headingOne${index}`} class="collapse" data-parent="#accordion2" id={`collapseOne${index}`} role="tabpanel" >
                                    <div class="card-body">
                                      {item.answer}
                                    </div>
                                </div>
                            </div>

                        })}

                    </div>

                    {/* <div aria-multiselectable="true" class="accordion accordion-color" id="accordion2" role="tablist">
                        <div class="card">
                            <div class="card-header" id="headingOne2" role="tab">
                                <a aria-controls="collapseOne2" aria-expanded="false" data-toggle="collapse" href="#collapseOne2" class="collapsed">ساخت یک دکمه زیبا CSS3</a>
                            </div>
                            <div aria-labelledby="headingOne2" class="collapse" data-parent="#accordion2" id="collapseOne2" role="tabpanel" >
                                <div class="card-body">
                                    لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingTwo2" role="tab">
                                <a aria-controls="collapseTwo2" aria-expanded="false" class="collapsed" data-toggle="collapse" href="#collapseTwo2">انیمیشن تاشو منوی پیمایش افقی</a>
                            </div>
                            <div aria-labelledby="headingTwo2" class="collapse" data-parent="#accordion2" id="collapseTwo2" role="tabpanel" >
                                <div class="card-body">
                                    لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingThree2" role="tab">
                                <a aria-controls="collapseThree2" aria-expanded="true" class="" data-toggle="collapse" href="#collapseThree2">ایجاد دکمه CSS3 با گوشه های گرد</a>
                            </div>
                            <div aria-labelledby="headingThree2" class="collapse show" data-parent="#accordion2" id="collapseThree2" role="tabpanel">
                                <div class="card-body">
                                    لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است
                                </div>
                            </div>
                        </div>
                    </div> */}

                </Col>
            </Row>
            <Modal isShow={showModal} close={handlerCloseClicked} title={titleModal} saveClick={(event) => handlerOnSaveModal(event)}>
                {childModal}
            </Modal>
        </PageWrapper>
    )
}

export default FrequncyQuestions