import React from 'react'
import Utils from '../../modules/utils'
import { TicketStatusTypes } from '../../store/consts';


function TicketList({ item, click }) {
  const util = new Utils();
  return (
    <tr key={item.id}>
      <td>

        <div class="btn-icon-list">
          <button class="btn ripple btn-primary btn-icon" onClick={(event) => click(event, item, "view")}><i class="fe fe-eye"></i></button>
          {item.status != 3 ?
              <button class="btn ripple btn-secondary btn-icon" onClick={(event) => click(event, item, "close")}><i class="fe fe-lock"></i></button>:null
          }
        </div>

      </td>
      <td>{item.group_name}</td>
      <td>{item.first_name} {item.last_name}</td>
      <td>{item.title}</td>
      <td>{TicketStatusTypes.filter((t) => t.value == item.status)[0].label}</td>

      <td>{util.toPersianDateTime(new Date(item.create_at))}</td>



    </tr>

  )
}

export default TicketList