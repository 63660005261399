import React, { useEffect, useState, useRef } from 'react'
import PageWrapper from '../../../components/template/PageWrapper/PageWrapper'
import PageTitle from '../../../components/template/PageTitle/PageTitle'
import PageCard from '../../../controls/PageCard/PageCard'
import Row from '../../../controls/Row/Row'
import Col from '../../../controls/Col/Col'
import * as appConsts from '../../../store/consts'
import API from '../../../modules/api'


import './HomeBanner.css'
import AttachSelector from '../../../components/Attachments/AttachSelector/AttachSelector'
import TextEditor from '../../SettingControls/TextEditor'
import Wrapper from '../../../hoc/Wrapper/Wrapper'
import TextEditLink from '../../SettingControls/TextEditLink'
function HomeBanner() {

    const [filterModal, setFilterModal] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedBanner, setSelectedBanner] = useState(null)
    const [isReload, setIsReload] = useState(false)


    const [homebannerState, setHomebannerState] = useState(
        {
            banner1_url: "./assets/front-images/banners/banner_01.png",
            banner1: "./assets/front-images/banners/banner_01.png",
            banner2_url: "./assets/front-images/banners/banner_02.png",
            banner2: "./assets/front-images/banners/banner_02.png",
            banner3_url: "./assets/front-images/banners/banner_03.png",
            banner3: "./assets/front-images/banners/banner_03.png",
            banner4_url: "./assets/front-images/banners/banner_04.png",
            banner4: "./assets/front-images/banners/banner_04.png",
            text1: "Powerful, easier and faster",
            text1_edit: false,
            text2: "Grow your software",
            text2_edit: false,
            text3: "faster & easier with Dashbord",
            text3_edit: false,
            text4: "The World's simplest and fast platform that brings everything together",
            text4_edit: false,
            text5: "Enter your email",
            text5_edit: false,
            text6: "TRY FOR FREE",
            text6_edit: false,
            text7: "Already using software?",
            text7_edit: false,
            text8: "Sign in",
            text8_edit: false,
        }
    );
    useEffect(() => {

        let arrNames = [];
        for (let index = 1; index <= 4; index++) {
            arrNames = [...arrNames, `home-banner${index}-attach-id`];
        }


        for (let index = 1; index <= 8; index++) {
            arrNames = [...arrNames, `home-banner-text${index}`];

        }
        console.log("txt", arrNames);
        (new API()).sendPostWithoutToken(`/api/settings/names`, { names: arrNames }, (result) => {
            let obj = {};
            console.log('result', result);
            for (let index = 1; index <= 4; index++) {
                arrNames = [...arrNames, `home-banner${index}-attach-id`];
                console.log("A", index);
                if (result[`home-banner${index}-attach-id`] != null) {
                    const myVal = result[`home-banner${index}-attach-id`];
                    console.log("B", index);
                    console.log("C", `${appConsts.api_url}/api/attachments/file/${myVal}`);
                    obj[`banner${index}`] = myVal;
                    obj[`banner${index}_url`] = `${appConsts.api_url}/api/attachments/file/${myVal}`;


                }
            }




            for (let index = 1; index <= 8; index++) {
                arrNames = [...arrNames, `home-banner-text${index}`];
                console.log("AA", index);
                if (result[`home-banner-text${index}`] != null) {
                    const myVal = result[`home-banner-text${index}`];
                    console.log("BBB", myVal);
                    obj[`text${index}`] = myVal;


                }
            }
            setHomebannerState({ ...homebannerState, ...obj })
            console.log("D", homebannerState);
            console.log("DSDS", result);

        });


    }, [isReload])


    const imageDoubleClick = (event) => {
        event.preventDefault();
        console.log("PPP", event.target)
        setFilterModal(["image"])
        setShowModal(true)
        setSelectedBanner(event.target);
    }
    const closeModal = (event) => {
        event.preventDefault();
        setShowModal(false)
    }
    const selectedImage = (event, item) => {
        event.preventDefault();
        if (selectedBanner != null) {

            (new API()).sendPostWithToken("/api/settings", { name: `home-${selectedBanner.id}-attach-id`, val: item.id }, (result) => {
                setHomebannerState({
                    ...homebannerState, [`${selectedBanner.id}`]: item.id
                    , [`${selectedBanner.id}_url`]: `${appConsts.api_url}/api/attachments/file/${item.id}`
                })
            })

            setShowModal(false)
        }

    }
    const selectedText = (event, name) => {
        event.preventDefault();

        setHomebannerState({ ...homebannerState, [`${name}_edit`]: true })
        console.log(homebannerState);
    }
    const closeText = (event, name) => {
        event.preventDefault();

        setHomebannerState({ ...homebannerState, [`${name}_edit`]: false })
        console.log(homebannerState);
    }
    const saveText = (event, cntrl) => {
        event.preventDefault();
        console.log(cntrl)
        if (cntrl != null) {

            (new API()).sendPostWithToken("/api/settings", { name: `home-banner-${cntrl.current.name}`, val: cntrl.current.value }, (result) => {
                setHomebannerState({ ...homebannerState, [`${cntrl.current.name}_edit`]: false, [`${cntrl.current.name}`]: cntrl.current.value, })
            })

            setShowModal(false)
        }

    }
    return (
        
            <Row>
                <Col size="lg-12">

                    <PageCard>
                        {/* <button className='btn btn-success' onClick={() => { setIsReload(!isReload) }}>Load</button> */}
                        <div className='banner_section'>
                            <div className="container">
                                <div className="banner_text">
                                    <div className="ban_inner_text aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500">
                                        {
                                            !homebannerState.text1_edit ? <span  ><TextEditLink name="text1" selectedText={selectedText} />{homebannerState.text1}</span> :
                                                <span>
                                                    <TextEditor name="text1" myState={homebannerState} close={closeText} save={saveText} />

                                                </span>
                                        }
                                        <h1>{!homebannerState.text2_edit ? <Wrapper>{homebannerState.text2} <TextEditLink name="text2" selectedText={selectedText} /></Wrapper> : <TextEditor name="text2" myState={homebannerState} close={closeText} save={saveText} />}
                                            <br />{!homebannerState.text3_edit ? <Wrapper>{homebannerState.text3} <TextEditLink name="text3" selectedText={selectedText} /></Wrapper> : <TextEditor name="text3" myState={homebannerState} close={closeText} save={saveText} />} </h1>
                                        <p>
                                            
                                        {!homebannerState.text4_edit ? 
                                        <Wrapper>{homebannerState.text4} <TextEditLink name="text4" selectedText={selectedText} /></Wrapper> : <TextEditor name="text4" myState={homebannerState} close={closeText} save={saveText} />}
                                         
                                            
                                            
                                            </p></div>
                                    <form action="submit" data-aos="fade-up" data-aos-duration="1500" className="aos-init aos-animate">
                                        <div className="form-group">
                                        {!homebannerState.text5_edit ?
                                            <Wrapper>
                                               <div className='d-flex justify-content-center '>
                                               <input type="text" className="form-control w-50" placeholder={homebannerState.text5} />
                                               <TextEditLink name="text5" selectedText={selectedText} />
                                               </div>

                                            </Wrapper>
                                            : <TextEditor name="text5" myState={homebannerState} close={closeText} save={saveText} />
                                        }
                                          {!homebannerState.text6_edit ?
                                            <Wrapper>
                                               <div className='d-flex justify-content-center '>
                                               <button className="btn btn_main">{homebannerState.text6}<i className="icofont-arrow-right"></i></button>
                                               <TextEditLink name="text6" selectedText={selectedText} />
                                               </div>

                                            </Wrapper>
                                            : <TextEditor name="text6" myState={homebannerState} close={closeText} save={saveText} />
                                        }
                                            
                                        </div><span>
                                        {!homebannerState.text7_edit ?
                                            <Wrapper>
                                            {homebannerState.text7} 
                                            <TextEditLink name="text7" selectedText={selectedText} />
                                            </Wrapper>
                                            : <TextEditor name="text7" myState={homebannerState} close={closeText} save={saveText} />
                                        }
                                        {!homebannerState.text8_edit ?
                                            <Wrapper>
                                            <a href="#">{homebannerState.text8}</a>
                                            <TextEditLink name="text8" selectedText={selectedText} />
                                            </Wrapper>
                                            : <TextEditor name="text8" myState={homebannerState} close={closeText} save={saveText} />
                                        }
                                            </span></form>
                                </div>
                                <div className="banner_images aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500">
                                    <img id="banner1" src={homebannerState.banner1_url} alt="image" onDoubleClick={(event) => imageDoubleClick(event)} />
                                    <div className="sub_images">
                                        <img className="moving_animation" id="banner2" src={homebannerState.banner2_url} alt="image" onDoubleClick={(event) => imageDoubleClick(event)} />
                                        <img className="moving_animation" id="banner3" src={homebannerState.banner3_url} alt="image" onDoubleClick={(event) => imageDoubleClick(event)} />
                                        <img className="moving_animation" id="banner4" src={homebannerState.banner4_url} alt="image" onDoubleClick={(event) => imageDoubleClick(event)} /></div></div></div>

                        </div>


                    </PageCard>
                </Col>
                {showModal ? <AttachSelector isShow={showModal} close={closeModal} filter={filterModal} select={selectedImage} /> : null}
            </Row>
       
    )
}

export default HomeBanner