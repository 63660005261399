import React, { useEffect, useState, useRef, useCallback } from 'react'
import PageTitle from '../../../components/template/PageTitle/PageTitle'
import PageWrapper from '../../../components/template/PageWrapper/PageWrapper'
import PageCard from '../../../controls/PageCard/PageCard'
import Row from '../../../controls/Row/Row'
import Col from '../../../controls/Col/Col'
import MyQuilEditor from '../../../controls/MyQuilEditor/MyQuilEditor'
import './TextValues.css'
import API from '../../../modules/api'


function TextValues() {
    const[langStates,setLangStates] = useState([])

    const[textStates,setTextStates] = useState({
        about_us:"",
        site_login:"",
        role_login:"",


    })
    const loadLangs=()=>{
        let obj={};
        (new API()).sendGETWithToken("/api/langs", (result) => {
            setLangStates(result);
            // result.map((item)=>{
            //     setTextStates({...textStates,obj})
            // })
            
          })
    }

    const handleSaveClicked=()=>{
        let arrNames = Object.keys(textStates).map((item)=> {

            (new API()).sendPostWithToken("/api/settings", { name: item, val: textStates[item] }, (result) => {

                
            })

        });
      
    }
    const handleEditorChange=(name,content)=>{
    if(textStates[name]!=content){
        setTextStates({
            ...textStates,
            [name]:content

    })
    }
  

       

     
    }


    useEffect(() => {
        loadLangs();
       // loadData();



    }, [])
    useEffect(() => {
       // loadLangs();
        loadData();



    }, [langStates])


    const loadData = () => {

        let arrNames = Object.keys(textStates).map((item)=> item);

        langStates.map((item)=>{
           if(arrNames.indexOf(`about_us_${item.name}`)==-1){
            arrNames=[...arrNames,`about_us_${item.name}`];
           }
        })
console.log('ABC', arrNames)
        let obj = { ...textStates };
        try {

            (new API()).sendPostWithoutToken(`/api/settings/names`, { names: arrNames }, (result) => {
                console.log("TRE", result)
                arrNames.forEach((item)=>{
                    if (result[item] != null) {
                       obj[item]=result[item];
                    }
                })
              

             
                console.log("WERTY", obj)
                setTextStates({ ...textStates, ...obj });
            });

        } catch (error) {

            console.log("ERR", error)
        }



    }


    const handleTextChange = (e) => {
        const { name, value } = e.target;


        // alert(name)
        // alert(value)
        // setHomeState({
        //     ...homeState,
        //     [name]: value,
        // });
        // setIsChangeState(true);

    };

    return (
        <PageWrapper>
            <PageTitle title="مقادیر متنی سایت" />
            <div className="btn btn-list mb-2">
                        <button className="btn ripple btn-primary" onClick={handleSaveClicked}>
                            <i className="fe fe-plus-circle mx-1 mt-3"></i>
                            ذخیره </button>
                    </div>
            <Row>

                {
                    langStates.map((item=>{

                      return  <Col size="lg-12 editor-cnt">

                        <PageCard>
                            <Row>
                                <Col size="lg-12">
    
                                    <h1>درباره ما {item.title}</h1>
                                    <MyQuilEditor name={`about_us_${item.name}`} value={textStates[`about_us_${item.name}`]} change={handleEditorChange} />
    
                                </Col>
                            </Row>
    
                        </PageCard>
    
                    </Col>

                    }))
                }

                <Col size="lg-12 editor-cnt">

                    <PageCard>
                        <Row>
                            <Col size="lg-12">

                                <h1>درباره ما</h1>
                                <MyQuilEditor name="about_us" value={textStates.about_us} change={handleEditorChange} />

                            </Col>
                        </Row>

                    </PageCard>

                </Col>
                <Col size="lg-12 editor-cnt">

                    <PageCard>
                        <Row>
                            <Col size="lg-12">

                                <h1>لاگین سایت</h1>
                                <MyQuilEditor name="site_login" value={textStates.site_login} change={handleEditorChange} />

                            </Col>
                        </Row>

                    </PageCard>

                </Col>
                <Col size="lg-12 editor-cnt">

                    <PageCard>
                        <Row>
                            <Col size="lg-12">

                                <h1>لاگین نقشها</h1>
                                <MyQuilEditor name="role_login" value={textStates.role_login} change={handleEditorChange} />

                            </Col>
                        </Row>

                    </PageCard>

                </Col>
            </Row>
        </PageWrapper>

    )
}

export default TextValues