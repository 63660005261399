import React, { useCallback, useEffect, useState } from 'react'
import API from '../../modules/api'
import PageWrapper from '../../components/template/PageWrapper/PageWrapper'
import PageTitle from '../../components/template/PageTitle/PageTitle'
import PageCard from '../../controls/PageCard/PageCard'
import Row from '../../controls/Row/Row'
import Col from '../../controls/Col/Col'
import Table from '../../controls/Table/Table'
import THead from '../../controls/Table/THead'
import GroupsList from '../../components/TicketGroups/GroupsList'
import Modal from '../../controls/Modal/Modal'
import EditGroup from '../../components/TicketGroups/EditGroup'
import AddGroup from '../../components/TicketGroups/AddGroup'
import Swal from 'sweetalert2';
import Utils from '../../modules/utils';

import { useNavigate } from "react-router-dom";

function TicketGroups() {
  const util = new Utils();

  const [groupsState, setGroupsState] = useState([])
  const [showModal, setShowModal] = useState(false);
  const [childModal, setchildModal] = useState(null);
  const [titleModal, settitleModal] = useState("");
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedAction, setSelectedAction] = useState(null);
  const navigate = useNavigate();


  useEffect(() => {

    (new API()).sendGETWithToken("/api/tickets/groups", (result) => {
      setGroupsState(result);
    })

  }, [])

  const AddGroupClicked = (event) => {
    event.preventDefault();
    setSelectedGroup(null)
    settitleModal("افزودن گروه")
    setSelectedGroup({})
    setSelectedAction("add")
    setchildModal(<AddGroup item={null} changed={onSetGroupUpdate} />)
    setShowModal(true)
  }
  const onSaveModal = (event) => {
    event.preventDefault();

    switch (selectedAction) {
      case "edit":
        if (selectedGroup) {
          (new API()).sendPatchWithToken(`/api/tickets/groups/${selectedGroup.id}`, selectedGroup, (result) => {
            console.log(result);
            setSelectedAction(null)
            setSelectedGroup(null)
            setShowModal(false)
            //console.clear()
            setGroupsState(result);

          })
        }
        break;
      case "add":
        if (selectedGroup) {
          (new API()).sendPostWithToken(`/api/tickets/groups`, selectedGroup, (result) => {
            console.log(result);
            setSelectedAction(null)
            setSelectedGroup(null)
            setShowModal(false)
            //console.clear()
            setGroupsState(result);

          })
        }
        break;

      default:
        break;
    }




  }


  const onDeleteClick = (event, item) => {
    util.showDeleteMessage(()=> {


      (new API()).sendPostWithToken(`/api/tickets/groups/delete/${item.id}`, {}, (result) => {
        console.log(result);
        setSelectedAction(null)
        setSelectedGroup(null)
        setShowModal(false)
        //console.clear()
        setGroupsState(result);
        util.showAlert("تائید!","اطلاعات تغییر یافت");
      })


     
    });
    
   
 
  }
  const closeGroupClicked = (event) => {
    event.preventDefault();
    setSelectedGroup(null)
    setShowModal(false)
  }
  const onSetGroupUpdate = (item) => {
    //event.preventDefault();
    console.log("VVV", item)
    setSelectedGroup(item)
  }
  const GroupItemClicked = (event, item, action) => {
    event.preventDefault();
    setSelectedGroup(null)
    switch (action) {
      case "edit":
        settitleModal("ویرایش گروه")

        setSelectedGroup(item)
        setSelectedAction(action)
        setchildModal(<EditGroup item={item} changed={onSetGroupUpdate} />)
        setShowModal(true)
        break;
      case "delete":
        onDeleteClick(event,item)
        break;
      case "words":
        navigate(`/words/${item.id}`)
        break;

      default:
        break;
    }
  }

  return (
    <PageWrapper>
      <PageTitle title="گروهها" />
      <Row>
        <Col size="lg-12">

          <PageCard>
            <div className="btn btn-list mb-2">
              <button className="btn ripple btn-primary" onClick={AddGroupClicked}>
                <i className="fe fe-plus-circle mx-1 mt-3"></i>
                گروه جدید </button>
            </div>
            <Table>
              <THead>
                <th className='w-25'>#</th>
                <th>کد</th>
                <th>عنوان</th>

              </THead>
              <tbody>
                {

                  groupsState.map((item) => {
                    return <GroupsList item={item} click={GroupItemClicked} />

                  })

                }
              </tbody>
            </Table>
            <Modal isShow={showModal} close={closeGroupClicked} title={titleModal} saveClick={(event) => onSaveModal(event)}>
              {childModal}
            </Modal>
          </PageCard>
        </Col>
      </Row>

    </PageWrapper>

  )
}

export default TicketGroups